import React, { useEffect, useState } from "react";
import MicImage from "../../icons/micImage.png";
import "./Input.css";
import Mic from "../../icons/Mic.png";
import MicVector from "../../icons/Vector.png";
import { useLocation } from "react-router-dom";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import getText from "../../utils/getText";
import { getLocalStorageInfo } from "../../utils/getInfo";
const Input = ({
  setMessage,
  sendMessage,
  message,
  setIsLisning,
  isLisning,
  isLoading,
  startListening,
  setIsFirstMic,
  setIsLisningFirst,
  setIsMicLisning,
  setClickMic,
  isMicLisning,
  sendUserText,
  setSendUserText,
  resetTranscript,
  sendMessageWithType,
  adminName,
  userLanguage,
  chattingInfo,
}) => {
  const location = useLocation();
  const [isVaild, setIsVaild] = useState(false);
  const [room, setRoom] = useState("");
  const checkJoinAdmin = async (adminInfo, room) => {
    const response = await fetch(
      `${process.env.REACT_APP_END_POINT}joinManagerCheck/${room}/${adminInfo.adminName}`
    );
    const data = await response.json();
    if (data === "TRUE") {
      setIsVaild(false);
    } else {
      setIsVaild(true);
    }
  };

  useEffect(() => {
    const adminInfo = getLocalStorageInfo("adminInfo");
    const room = new URLSearchParams(location.search).get("room");
    setRoom(room ?? "");
    if (room === null) return; //사용자들 여기서 끝

    if (adminInfo === null) {
      //관리자 페이지로 접속하였으나 로그인 정보가 없는 경우
      alert("로그인 후 다시 이용해주세요.");
      window.location.href = "https://cschat.moneyplex.net/chattinglist";
      return;
    }

    //지정된 담당자만 채팅 가능. 미지정일 경우 모두가 채팅 가능. 미지정이 아닐경우 지정된 관리자 이름을 가진 사람만 이용 가능
    if (adminName === "미배정" || adminName === "") {
      // setIsVaild(adminName !== adminInfo.adminName);
      setIsVaild(false);
    } else {
      checkJoinAdmin(adminInfo, room);
    }
  }, [adminName]);

  useEffect(() => {
    const handleMouseDown = () => {};

    const handleMouseUp = () => {
      if (isMicLisning) {
        setTimeout(() => {
          setSendUserText(true);
          SpeechRecognition.abortListening();
          resetTranscript();
        }, 1000);

        setIsMicLisning(false);
      }
    };

    window.addEventListener("pointerdown", handleMouseDown);
    window.addEventListener("pointerup", handleMouseUp);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("pointerdown", handleMouseDown);
      window.removeEventListener("pointerup", handleMouseUp);
    };
  }, [isMicLisning]);
  if (!chattingInfo?.isChattingActive) {
    return (
      <div
        style={{
          textAlign: "center",
          fontSize: 30,
        }}
      >
        종료된 채팅입니다.
      </div>
    );
  }
  return (
    <div
      style={{
        alignItems: "center",
      }}
      className="form"
    >
      <input
        style={{
          width: "350%",
        }}
        className="input"
        type="text"
        placeholder={
          isVaild
            ? "지정된 담당자만 채팅을 할 수 있습니다."
            : getText(userLanguage, room, "typeMessage")
        }
        value={message}
        disabled={isVaild}
        onChange={({ target: { value } }) => setMessage(value)}
        onKeyPress={(event) =>
          event.key === "Enter" ? sendMessageWithType() : null
        }
      />
      {!isVaild && (
        <div
          style={{
            minWidth: 150,
            display: "flex",
            width: "100%",
            height: "100%",
            border: "1px solid #D9D9D9",
            maxWidth: "150px",
          }}
        >
          <>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                maxWidth: "75px",
              }}
            >
              <button
                style={{
                  color: message === "" ? "#dedede" : "black",
                  fontSize: 16,
                }}
                className="sendButton"
                onClick={(e) => {
                  if (!isLoading && message !== "") {
                    sendMessageWithType();
                  }
                }}
              >
                {isLoading ? (
                  <div className="loading-wrap loading-wrap--js">
                    <div className="loading-spinner loading-spinner--js"></div>
                    <p id="loadingMessage"></p>
                  </div>
                ) : (
                  getText(userLanguage, room, "send")
                )}
              </button>
            </div>

            <div
              onPointerDown={() => {
                if (!isLoading) {
                  setIsMicLisning(true);
                  setClickMic(true);
                  setMessage("");
                }
              }}
              onPointerUp={async () => {
                setTimeout(() => {}, 2000);
              }}
              style={{
                background: isMicLisning ? "#E6E6E6" : "#FFC028",
                width: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                maxWidth: "75px",
              }}
            >
              <img height={"30%"} src={MicVector} />
            </div>
          </>
        </div>
      )}
    </div>
  );
};

export default Input;
